export function commons(){
    
    //バーガーメニュー
    $("#HEADER .gnavi").on("click", function () {
        if ($(window).width() < 769) {
            $(this).children("ul").stop().slideToggle(200);
            if ($(this).is('.addClose')) {
                $(this).removeClass('addClose');
            } else {
                $(this).addClass('addClose');
            }
        }
    });
	
    // Aタグクリック時のスクロール
	$('a[href^="#"]').on("click",function() {
		let href= $(this).attr("href");
		let target = $(href == "#" || href == "" ? 'html' : href);
		let position = target.offset().top - $("#HEADER").height();
		$('html,body').animate({scrollTop:position},600);

		return false;
	});
	
	const controller = new ScrollMagic.Controller();
	//アニメーション
	$(".motion-animate").each(function(){
		let s = $(this).offset().top - 300;
		let d = $(this);
		new ScrollMagic.Scene({
			triggerElement:'body',
			offset: s
		})
		.on('start', function(){
			d.addClass("active");
		})
		//.addIndicators()
		.addTo(controller);
	});
	
	//ヘッダー表示切り替え
	if($(".firstview").length > 0){
		//risize
		var h = Math.floor($(window).height());
		$(".firstview").css({
			height:h
		});
		$("#WRAPPER,#FOOTER").css({
			top:h
		});
		
		$(window).scroll(function(){
				var sc = $(this).scrollTop();
				var demo = $("#WRAPPER").offset().top +1;
				if(sc > demo){
					$("#HEADER").addClass("active");
				} else {
					$("#HEADER").removeClass("active");
				}
		 });
	}else {
			$("#HEADER").addClass("active");
	}
	
}