export function indexs(){
    
    if($(".firstview").length > 0){
		
        //VEGAS
        const target = $(".firstview");
        const photo = new Array();
        for(let i=0; i < target.find(".hide img").length; i++){
            photo[i] = target.find(".hide img").eq(i).attr("src");
        }
        const photos = Object.entries(photo).map(([src, value]) => ({'src': value}));
        target.vegas({
            overlay: false,
            timer:false,
            transitionDuration: 2000,
            delay: 6500,
            animation: 'kenburns',
            animationDuration: 20000,
            loop:false,
            slides: photos
        });
    }
	
	if($(".INDEX").length > 0){
	  var mySwiper = new Swiper ('.swiper-container', {
		// ここからオプション
		loop: true,
		slidesPerView: 4,
		spaceBetween: 40,
		loopAdditionalSlides: 2,
		centeredSlides : true,
		autoplay: {
		 delay:2500,
		},
		pagination: {
		  el: '.swiper-pagination',
		},
		 breakpoints: {
			 640: {
				 slidesPerView: 2,
				 spaceBetween: 15
			 }
		 }
	  })
		
	}
	
}